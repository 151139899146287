import { useEffect, useRef, useState } from "react";
import Table from "../../atom/table";
import FadeIn from "react-fade-in";
import {
  CrossIcon,
  DeleteIcon,
  DownloadIcon,
  EyeIcon,
  FilterIcon,
  InfoIcon,
  LineBulletinIcon,
  TableSortIconDownArrow,
  TranscriptionIcon,
} from "../../utils/icons/defaultIcons";
import ComponentMap from "../../atom/componentmap";
import { Backdrop, CircularProgress } from "@mui/material";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import TableTopBar from "../modal/tableTopBar";
import { useDispatch, useSelector } from "react-redux";
import { activeCallRecording } from "../../toolkit/reducers/callRecordingsSlice";
import { motion } from "framer-motion";
import Modal from "../../atom/modal";
import * as deleteIcon from "../../utils/lottie/deleteIcon.json";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import * as downloadLottie from "../../utils/lottie/downloadLottie.json";
import {
  AddSearchNameForFilter,
  callTranscript,
  downloadRecordings,
  getCallRecords,
  getLegacyCDRRecordsForDownload,
  getSearchFilterResults,
  getSearchNameForFilter,
  getToken,
  removeSearchFilterResults,
} from "../../services/api";
import Notification from "../../atom/Notification";
import Loader from "../loader";
import {
  actualDataToMapData,
  downloadFileFromURL,
  getTimeFromstring,
} from "../../services/utilities";
import { Cookies } from "react-cookie";
import PaginationButtons from "../../atom/pagination";
import { isArray } from "util";
import { number } from "yargs";
import { useWindowSize } from "../../atom/useWindowSize";
import moment from "moment";
import { getENVData } from "../../config/environment";
import { Authv2 } from "../authv2/authv2";
import { set } from "date-fns";
import TransacribeModal from "./transacribeModal";

function LegacyCdr() {
  const cookies = new Cookies();
  const saveNameModalRef = useRef<HTMLInputElement>(null);
  const [saveSearchmodal, setSaveSearchModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [playerModal, setPlayerModal] = useState<boolean>(false);
  const [transcribe, setTranscribe] = useState<any>({});

  const [onDeleteRec, setOnDeleteRec] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<any>();
  const [savedSearches, setSavedSearches] = useState<any>([]);
  const [isHovered, setIsHovered] = useState<number>();
  const [searchtext, setSearchText] = useState<any>("");
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);
  const [apiLoadingStatus, setApiLoadingStatus] = useState<boolean>(false);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const [filter, setFilter] = useState<boolean>(false);
  const [csvDownload, setCsvDownload] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(25);
  const token = useSelector((state: any) => state.auth.token);
  const [hideDropdownValues, setHideDropdownValues] = useState<any>([
    { name: "Direction", checked: false },
    { name: "Orginal Extension", checked: false },
    { name: "Transfer Extension", checked: false },
    { name: "Groups", checked: false },
    { name: "Call Organization", checked: false },
    { name: "Call Destination", checked: false },
    { name: "Start Time", checked: false },
    { name: "End Time", checked: false },
    { name: "Total Time", checked: false },
  ]);
  var d = new Date();

  let startData = d.setHours(0, 0, 0, 0);
  let endData = d.setHours(23, 59, 59, 59);

  const [searchFormData, setSearchFormData] = useState<any>([
    {
      title: "Direction",
      name: "direction",
      value: "All",
      type: "dropdown",
      displayProps: { label: "label", value: "label" },
      childrens: [
        { label: "All", value: "all" },
        { label: "Inbound", value: "Inbound" },
        { label: "Outbound", value: "Outbound" },
        { label: "Internal", value: "Internal" },
      ],
      hide: {
        status: true,
        default: false,
      },
    },
    {
      title: "Original Extension",
      name: "orig_ext",
      value: "",
      hide: {
        status: true,
        default: false,
      },
    },
    {
      title: "Transfer Extension",
      name: "transfer_exts",
      value: "",
      hide: {
        status: true,
        default: false,
      },
    },
    {
      title: "Calling Number",
      name: "calling_number",
      value: "",
      hide: {
        status: true,
        default: true,
      },
    },

    {
      title: "Dialed Number",
      name: "dialed_number",
      value: "",
      hide: {
        status: true,
        default: false,
      },
    },
    // {
    //   title: "",
    //   name: "date_format",
    //   type: "radio",
    //   required: true,
    //   childrens: [
    //     { label: "Today", value: "Today" },
    //     { label: "Date Range", value: "DateRange" },
    //   ],
    //   value: "Today",
    //   reference: {
    //     items: ["start_time", "end_time"],
    //     action: "disabled",
    //     condition: {
    //       operator: "!=",
    //     },
    //   },
    //   layout: 6,
    // },
    {
      title: "From",
      name: "start_time",
      value: moment(startData).format("YYYY-MM-DD HH:mm:ss") || "",
      type: "dateTimePicker",
      // disabled: true,
      // reference: {
      //   items: "end_time",
      //   action: "disabled",
      //   condition: {
      //     operator: ">",
      //   },
      // },
    },
    {
      title: "To",
      name: "end_time",
      value: moment(endData).format("YYYY-MM-DD HH:mm:ss") || "",
      type: "dateTimePicker",
      // disabled: true,
      // reference: {
      //   items: "start_time",
      //   action: "disabled",
      //   condition: {
      //     operator: "<",
      //   },
      // },
    },
    // add groups in search
    // {
    //   title: "Groups",
    //   name: "groups",
    //   type: "dropdown",
    //   value: "",
    //   displayProps: { label: "label", value: "label" },
    //   childrens: [
    //     { label: "Data1", value: "Data1" },
    //     { label: "Data2", value: "Data2" },
    //   ],
    // },
  ]);

  const [saveSearchName, setSaveSearchName] = useState<any>([
    {
      title: "Enter name",
      name: "name",
      value: "",
      required: false,
      errormessage: "Name is required",
    },
  ]);

  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "",
      // class: "text-center !w-[100px]",
      // title: "Call Info",
      type: "checkbox",
      selectAll: true,
      // hide: {
      //   status: true,
      //   default: true,
      // },
    },
    {
      key: "start_date",
      title: "Date",
      sort: { direction: "desc", active: true },
      hide: {
        status: true,
        default: true,
      },
    },
    {
      key: "start_time",
      title: "Start Time",
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "end_time",
      title: "End Time",
      sort: { direction: "asc", active: false },
      hide: {
        status: false,
        default: false,
      },
    },
    {
      key: "direction",
      title: "Direction",
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "orig_ext",
      title: "Original Extension",
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "transfer_exts",
      title: "Transfer Extension",
      sort: { direction: "asc", active: false },
      class: "max-w-[240px]  truncate",
      hide: {
        status: true,
        default: false,
      },
    },
    // { key: "data4", title: "Groups", icon: <TableSortIconDownArrow /> }, add in this future update backend still working on this
    {
      key: "calling_number",
      title: "Calling Number",
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: true,
      },
    },
    {
      key: "dialed_number",
      title: "Dialed Number",
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },

    {
      key: "total_time",
      title: "Total Time",
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    // {
    //   key: "action_button",
    //   title: "",
    //   type: "actionIcon",
    //   class: "text-right pr-[20px]",
    //   icon: <DownloadIcon />,
    //   action: {
    //     title: "Download",
    //     command: "download",
    //     action: "click",
    //   },
    // },
    {
      key: "action_button",
      title: "",
      type: "audioPlayer",
      class: "",
      hide: {
        status: true,
        default: true,
      },
    },

    {
      key: "action_button",
      title: "",
      type: "actionIcon",
      class: "w-10",
      icon: <TranscriptionIcon />,
      action: {
        title: "Transcribe",
        command: "transcribe",
        action: "click",
      },
    },
  ]);
  const [playerTableTitles, setPlayerTableTitles] = useState<any>([
    {
      key: "extension",
      title: "Extension",
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "start_time",
      title: "Start Time",
      type: "time",
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "stop_time",
      title: "Stop Time",
      type: "time",
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "action_button",
      title: "Action",
      type: "audioPlayer",
      class: "text-right pr-[40px]",
      hide: {
        status: true,
        default: true,
      },
    },
  ]);
  const [playerTableData, setPlayerTableData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);

  const onHandleSaveSearchName = (result: any) => {
    if (result?.name === "") {
      Notification("Required", "Filter name is required", "danger");
    } else {
      let payload = { ...filterData };
      payload.filter_name = result?.name;
      payload.start_time = new Date(payload.start_time).getTime() / 1000;
      payload.end_time = new Date(payload.end_time).getTime() / 1000;

      // filterData.start_time = payload.start_time * 1000;
      // filterData.end_time = payload.end_time * 1000;

      // if (isNaN(payload.start_time)) {
      //   delete payload.start_time;
      // } else {
      //   payload.start_time = payload.start_time * 1000;
      // }

      // if (isNaN(payload.end_time)) {
      //   delete payload.end_time;
      // } else {
      //   payload.end_time = payload.end_time * 1000;
      // }
      AddSearchNameForFilter(payload)
        .then((resultData: any) => {
          const res: any = resultData.data;

          if (res.data) {
            setSaveSearchModal(false);
            getSearchFilterNames();
            Notification("Failed", "Oops! Something went wrong", "danger");
          } else {
            setSaveSearchModal(false);
            getSearchFilterNames();
          }
        })
        .catch((error: any) => {
          Notification("Failed", "Oops! Something went wrong", "danger");
        });
    }
  };
  const [sort, setSort] = useState<any>({
    key: "start_date",
    order: "DESC",
  });
  const onHandleCancel = () => {
    let dummmyData = [...saveSearchName];
    dummmyData[0].value = "";
    setSaveSearchName(dummmyData);
    setSaveSearchModal(false);
  };
  const dispatch = useDispatch();
  const value = useSelector((state: any) => state.Call.callName);
  const activeMenu = useSelector((state: any) => state.menuItem.value);

  const change = (activeData: number) => {
    dispatch(activeCallRecording(activeData));
  };

  const closeDeleteModal = () => {
    setDeleteModal(!deleteModal);
    setOnDeleteRec(false);
  };
  const handleClosePlayerModal = () => {
    setPlayerModal(!playerModal);
    let playerData = [...playerTableData];
    playerData.map((item: any) => {
      item.playingURL = "";
    });
    setPlayerTableData(playerData);
  };
  const deleteRecording = () => {
    setOnDeleteRec(!onDeleteRec);
  };

  const getCallRecording = () => {
    setLoading(true);
    let payload = {
      limit: 10,
      pageId: 1,

      // filters: {
      //   originalextension: 106,
      // },
      // order_by: {
      //   key: "start_time",
      //   order: "DESC",
      // },
      //  search:"121"
    };
    getCallRecords(payload)
      .then((resultData: any) => {
        const result: any = resultData.data;
        setTableData(result?.data);
        setLoading(false);
      })
      .catch((err: any) => {
        Notification("error", "Oops! Something went wrong", "danger");
        setLoading(false);
      });
  };

  const onHandleSearchByFilter = (filters: any) => {
    setButtonLoader(true);
    setFilter(false);
    setApiLoadingStatus(true);
    const filterCopy = { ...filters };
    console.log("fghjk", filterCopy);
    setFilterData(filterCopy);
    setPageNumber(1);
    onHandleTableSearch({ filters: filterCopy, pageNumber: 1 });
    const updateData = [...saveSearchName];
    updateData[0].value = "";
    setSaveSearchName(updateData);
  };

  const handleResetFilter = () => {};

  const getSearchFilterNames = () => {
    getSearchNameForFilter("").then((resultData: any) => {
      const result: any = resultData.data;
      setSavedSearches(result);
      setButtonLoader(false);
      setApiLoadingStatus(false);
    });
  };

  const onHandleSavedSearchResults = (node: any, index: number) => {
    setButtonLoader(true);
    setApiLoadingStatus(true);
    let data: any = {
      start_time: node.start_time * 1000,
      end_time: node.end_time * 1000,
      // date_format: 'Today',
      direction: node.direction,
      orig_ext: node.orig_ext,
      transfer_exts: node.transfer_exts,
      orig_caller_id: node.orig_caller_id,
      orig_callee_id: node.orig_callee_id,
    };
    setPageNumber(1);
    console.log("updateFilterupdateFilter SavedSaearchResults", data);

    setSearchFormData(actualDataToMapData([...searchFormData], data));
    setFilterData(data);
    onHandleTableSearch({ filters: data, pageNumber: 1 });

    console.log("searchres", node);
    // getSearchFilterResults("", node?.uuid).then((resultData: any) => {
    //   const result: any = resultData.data;
    //   setSearchFormData(actualDataToMapData([...searchFormData], result[0]));
    //   onHandleSearchByFilter(node);
    // });
  };

  const onHandleRemove = (node: any) => {
    removeSearchFilterResults("", node?.uuid).then((resultData: any) => {
      const result: any = resultData.data;
      getSearchFilterNames();
    });
  };
  // TODO onHandleSort
  const onHandleSort = (node: any, orderBy: string) => {
    console.log("sorttt", node);
    setSort({ key: node.key, order: orderBy });
    onHandleTableSearch({ sort_column: node.key, sort_direction: orderBy });
  };
  const onHandleTableSearch = (options: {
    limit?: number;
    filters?: any;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    setResultNotFound(false);
    // setPageNumber(options.pageNumber);
    setApiLoadingStatus(true);
    // setLoading(true);
    setTableLoader(true);
    console.log("checkthis", options.filters, "filterdata", filterData);
    var updateFilter: any = {};

    if (options.filters) {
      updateFilter = { ...options.filters };
      updateFilter.start_time =
        new Date(updateFilter.start_time).getTime() / 1000;
      updateFilter.end_time = new Date(updateFilter.end_time).getTime() / 1000;
    } else {
      updateFilter = { ...filterData };
      updateFilter.start_time =
        new Date(updateFilter.start_time).getTime() / 1000;
      updateFilter.end_time = new Date(updateFilter.end_time).getTime() / 1000;
    }

    Object.keys(updateFilter).forEach((key) => {
      if (updateFilter[key] === "") {
        delete updateFilter[key];
      }
    });
    if (updateFilter.direction === "All") {
      delete updateFilter.direction;
    }

    var skey = options?.sort_column || sort.key;
    if (skey === "start_date") {
      skey = "start_time";
    }
    let payload = {
      limit: options?.limit || perPage,
      pageId: options?.pageNumber || pageNumber,
      filters: updateFilter,
      sort_params: {
        sort_column: skey,
        sort_direction: options?.sort_direction || sort.order,
      },
      search:
        options?.searchtext === undefined
          ? searchtext
          : options?.searchtext === ""
          ? ""
          : options?.searchtext,
    };
    // sort_column: options?.sort_column || sort.key,
    // sort_direction: options?.sort_direction || sort.order,
    console.log("filterDatafilterData", filterData);
    getCallRecords(payload)
      .then((resultData: any) => {
        const result: any = resultData.data;
        if (!resultData?.success || result?.data?.length === 0) {
          setResultNotFound(true);
          setTableData([]);
          setApiLoadingStatus(false);
          setButtonLoader(false);
        } else {
          setResultNotFound(false);
          setApiLoadingStatus(false);
        }
        result?.data.map((node: any, index: any) => {
          node.start_date = moment(new Date(node.origination_ts)).format(
            "MM/DD/YYYY"
          );
          node.start_time = moment(new Date(node.origination_ts)).format("LTS");
          node.end_time = moment(new Date(node.end_ts)).format("LTS");
        });

        setPageCount(result?.total_count);
        setTableData(result?.data);
        setLoading(false);
        setButtonLoader(false);
        // } else {
        // Notification("Failed", "Oops! Something went wrong", "danger");
        // setLoading(false);
        // }
      })
      .catch((error: any) => {
        setTableData([]);
        setResultNotFound(true);
        setButtonLoader(false);
        setLoading(false);
        setApiLoadingStatus(false);
      });
  };

  const filterTable = () => {
    setFilter(!filter);
  };
  const handleChange = (number: number) => {
    const selectAllData = tableTitles.find(
      (item: any) => item.checked === true && item.selectAll === true
    );
    if (selectAllData) {
      actionHandler("selectall", selectAllData);
    }
    onHandleTableSearch({
      pageNumber: number,
    });
    setPageNumber(number);
  };

  useEffect(() => {
    var filters = {
      start_time: searchFormData[5].value,
      end_time: searchFormData[6].value,
    };
    console.log("searchFormData", filters);
    onHandleTableSearch({ filters, pageNumber: 1 });
    filters.start_time = new Date(filters.start_time).getTime();
    filters.end_time = new Date(filters.end_time).getTime();
    setFilterData(filters);
  }, []);

  useEffect(() => {
    const local: any =
      window.sessionStorage.getItem("lagacyCDRTableTitle") || null;
    const result = JSON.parse(local);
    // var result = cookies.get("lagacyCDRTableTitle");
    // debugger
    if (result) {
      var data = [...tableTitles];
      data.map((node: any) => {
        if (result[node.key] !== undefined) {
          console.log("result", node?.hide);
          if (node.hide) {
            node.hide.status = result[node.key];
          }
        }
      });
      setTableTitles(data);
    }
    const handleClickOutside = (event: any) => {
      if (
        saveNameModalRef.current &&
        !saveNameModalRef.current.contains!(event.target)
      ) {
        setSaveSearchModal(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [saveSearchmodal]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    getSearchFilterNames();
    isFirstRender.current = false;
    // ---> StrictMode: The following is REQUIRED to reset/cleanup:
    return () => {
      isFirstRender.current = true;
    };
  }, []);
  const handleCSVDownload = () => {
    const copyData = { ...filterData };

    copyData.start_time = new Date(copyData.start_time).getTime() / 1000;
    copyData.end_time = new Date(copyData.end_time).getTime() / 1000;
    if (copyData.direction === "All") {
      delete copyData.direction;
    }

    let payload = {
      limit: perPage,
      pageId: 1,
      filters: copyData,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      search: searchtext,
      // start_time: copyData.from_date,
      // end_time: copyData.to_date,
    };
    setCsvDownload(true);

    getLegacyCDRRecordsForDownload(payload).then((result: any) => {
      if (result.data) {
        const baseURL = `${
          getENVData().baseURL
        }cr/tenants/${getToken()}/call_reports/download/csv/`;
        const url = `${baseURL}${result?.data.path.replace(
          "/opt/msa_call_reports/var/tmp/",
          ""
        )}`;
        downloadFileFromURL(url, "Call Recordings", token)
          .then((result: any) => {
            if (!result) {
              console.error("Download Failed");
            }
            setCsvDownload(false);
          })
          .catch((error: any) => {
            setCsvDownload(false);
            console.error("Download Failed");
          });
      } else {
        setCsvDownload(false);
        console.error("Download Failed");
      }
    });
  };

  const handleTableTitle = (data: any) => {
    setTableTitles(data);
    var status: any = {};
    data
      .filter((node: any) => node?.hide?.status !== undefined)
      .map((node: any) => {
        status[node.key] = node.hide.status;
      });
    window.sessionStorage.setItem(
      "lagacyCDRTableTitle",
      JSON.stringify(status)
    );
    // cookies.set("lagacyCDRTableTitle", JSON.stringify(status), {
    //   path: "/",
    // });
  };

  const handleSearch = (data: string) => {
    onHandleTableSearch({ searchtext: data, pageNumber: 1 });
  };
  const handlePerPage = (value: any) => {
    const selectAllData = tableTitles.find(
      (item: any) => item.checked === true && item.selectAll === true
    );
    if (selectAllData) {
      actionHandler("selectall", selectAllData);
    }
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleTableSearch({ limit: value, pageNumber: 1 });
  };
  const selectAllCheckbox = (data: any) => {
    console.log("checboxdata", data);
    const clone = [...tableData];
    const ttile = [...tableTitles];
    if (data?.selectAll === true) {
      const result: any = ttile.find((node: any) => {
        console.log("keyy.node", node.key, "data.key", data);
        return node.key === data.key;
      });
      result.checked = !result?.checked || false;
      clone.map((node: any) => {
        node.checked = result?.checked;
      });
    } else {
      const result = clone.find((node: any) => {
        return node.id === data.id;
      });
      result.checked = !result?.checked || false;
    }
    const result = clone.find((node: any) => {
      return node?.checked ? node?.checked : false;
    });
    const res: any = ttile.find((node: any) => {
      return node.type === "audioPlayer";
    });
    if (result) {
      res.checked = true;
    } else {
      res.checked = false;
    }
    setTableTitles(ttile);
    setTableData(clone);
  };
  const handleSyncSelect = (data: any) => {};
  const handleAllCheckBoxes = (dataItems: any) => {
    let copyTabletitles = [...tableTitles];
    copyTabletitles[0].selectAll = false;
    setTableTitles(copyTabletitles);
  };
  const downloadSelected = async (d: any) => {
    const clone = [...tableData];
    const ttile = [...tableTitles];
    const res: any = ttile.find((node: any) => {
      return node.type === "audioPlayer";
    });
    res.loading = true;
    setTableTitles(ttile);
    var payload: any = [];
    clone
      .filter((node: any) => {
        return node?.checked;
      })
      .map((node: any) => {
        payload.push({
          type: node.type,

          origination_ts: node.origination_ts,

          id: node.id,
        });
      });
    console.log("payloadpayload", payload);
    const result = await downloadRecordings({
      ids: payload,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const baseURL = `${
      getENVData().baseURL
    }cr/tenants/${getToken()}/call_recordings/download/csv/`;
    const url = `${baseURL}${result?.data.path.replace(
      "/opt/msa_call_reports/var/tmp/",
      ""
    )}`;
    downloadFileFromURL(url, "Legacy Call Recordings", token)
      .then((result: any) => {
        if (!result) {
          console.error("Download Failed!");
        }
        const th = [...tableTitles];
        const we: any = th.find((node: any) => {
          return node.type === "audioPlayer";
        });
        we.loading = false;
        setTableTitles(th);
      })
      .catch((error: any) => {
        console.error("Download Failed");
      });
    const th = [...tableTitles];
    const we: any = th.find((node: any) => {
      return node.type === "audioPlayer";
    });
    we.loading = false;
    setTableTitles(th);
  };
  const handleTranscribe = (data: any) => {
    console.log("data", data);
    setTranscribe(data);
  };
  const handleDownload = (data: any) => {};
  const actionHandler = (key: string, data: any) => {
    const functionCall: any = {
      selectall: selectAllCheckbox,
      change: selectAllCheckbox,
      downloadSelected: downloadSelected,
      transcribe: handleTranscribe,
      download: handleDownload,
    };
    const fName = functionCall[key];
    if (fName) {
      fName(data);
    } else {
      console.log("function not available...");
    }
  };
  return (
    <div
      data-testid="callArchive"
      className=" h-full py-3 flex relative gap-2.5"
    >
      <Backdrop className="!z-[99999999999]" open={apiLoadingStatus}>
        <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
      </Backdrop>

      {deleteModal && (
        <Modal
          icon={<DeleteIcon />}
          title={"Delete recording"}
          closeEvent={closeDeleteModal}
        >
          {onDeleteRec ? (
            <div className="gap-y-4 flex flex-col">
              <Lottiefy loop={false} json={deleteIcon} height={95} width={95} />
              <div className="flex items-center justify-center">
                Deleted successfully
              </div>
              <div className="text-center -bottom-10 ">
                <ThemedButton
                  children={"Ok"}
                  theme="secondary"
                  click={() => closeDeleteModal()}
                />
              </div>
            </div>
          ) : (
            <>
              <div>Are you sure you want to delete this recording?</div>
              <div className="text-right absolute inset-x-0 bottom-0 mb-2 mr-2">
                <ThemedButton
                  children={"Cancel"}
                  icon="cancel"
                  theme="ternary"
                  click={() => setDeleteModal(!deleteModal)}
                />
                <ThemedButton
                  children={"Delete"}
                  // disabled={childData === "loading" || childData.length !== 0}
                  icon="remove"
                  theme="primary"
                  click={() => {
                    deleteRecording();
                  }}
                />
              </div>
            </>
          )}
        </Modal>
      )}
      {transcribe?.recordings_l && (
        <Modal
          title={"Call Transcription"}
          closeEvent={() => {
            setTranscribe(null);
          }}
        >
          <TransacribeModal
            setTranscribe={setTranscribe}
            transcribe={transcribe}
          />
        </Modal>
      )}
      {playerModal && (
        <div className="h-[20px]">
          <Modal title={"Recordings"} closeEvent={handleClosePlayerModal}>
            <div>
              <Table
                tableTitles={playerTableTitles}
                setTableTitles={setPlayerTableTitles}
                tableData={playerTableData}
                setTableData={setPlayerTableData}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                onHandleSort={onHandleSort}
                audioPlayer={true}
                resultNotFound={resultNotFound}
                actions={true}
                width={
                  "xl:w-[calc(100vw-700px)] w-[calc(100vw-500px)] overflow-auto"
                }
                scrollHeight={"max-h-[calc(100vh-250px)] overflow-y-auto"}
                actionHandler={actionHandler}
              />
            </div>
          </Modal>
        </div>
      )}
      {!loading ? (
        <>
          {/* Side Search */}
          {/* {filter && ( */}
          <motion.div
            // initial={{ x: 200 }}
            // animate={{
            //   x: filter ? 0 : -150,
            //   transition: { duration: 0.3 },
            // }}
            className={`${
              filter ? "translate-x-0" : "hidden xl:block translate-x-0"
            } absolute duration-200 shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] left-0 xl:relative z-[8] h-[calc(100vh-125px)] xl:w-[260px] w-[260px] px-3 xl:px-0 rounded-md bg-[#ffff] overflow-y-auto`}
          >
            <div
              className={`xl:hidden float-right mt-3.5 mr-1 cursor-pointer rounded-full hover:bg-[#8080803b] w-[25px] h-[25px] flex justify-center items-center hover:transform hover:scale-105 transition duration-500 ease-in-out`}
              onClick={() => setFilter(false)}
            >
              <CrossIcon />
            </div>

            <div className="font-bold py-4 px-3">Search</div>

            <div className="smallPicker">
              <ComponentMap data={searchFormData} setData={setSearchFormData} />
            </div>
            {/* <div className="px-3 text-right text-[#6d80ced7]">reset</div> */}
            <div
              ref={saveNameModalRef}
              className="relative px-3 text-right text-[#6d80ced7] hover:text-[#6D81CE]"
            >
              <a
                id={"save_this_search"}
                className=" cursor-pointer"
                onClick={() => setSaveSearchModal(!saveSearchmodal)}
              >
                Save this search
              </a>
              {saveSearchmodal && (
                <div className="absolute w-[95%] h-fit -ml-1.5 mt-1 rounded-md z-[999] smallPicker bg-[#FEF4E9]">
                  <ComponentMap
                    data={saveSearchName}
                    setData={setSaveSearchName}
                  />
                  <ThemedButton
                    children={"Cancel"}
                    theme={"ternary"}
                    click={onHandleCancel}
                  />
                  <ThemedButton
                    children={"Save"}
                    theme={"primary"}
                    click={onHandleSaveSearchName}
                    valiData={saveSearchName}
                    change={setSaveSearchName}
                    disabled={filterData ? false : true}
                  />
                </div>
              )}
            </div>

            <div className="w-full flex justify-center items-center">
              {/* <ThemedButton
                children={"Reset"}
                theme={"secondary"}
                click={handleResetFilter}
                // valiData={searchFormData}
                // change={setSearchFormData}
                // loading={buttonLoader}
              /> */}
              <ThemedButton
                children={"Search"}
                theme={"primary"}
                click={onHandleSearchByFilter}
                valiData={searchFormData}
                change={setSearchFormData}
                loading={buttonLoader}
              />
            </div>
            {/* Saved searches div */}

            {savedSearches?.length > 0 && (
              <FadeIn>
                <div className="h-fit w-[94%] ml-2 rounded-sm mt-5">
                  <a className="text-[#898989]"> Saved searches</a>
                  <div className="mt-1.5 max-h-[183px] overflow-y-auto overflow-x-hidden bg-[#F2F4FB]">
                    {savedSearches?.map((node: any, index: number) => {
                      return (
                        <div
                          id={`save_search_${index}`}
                          key={node?.uuid}
                          className="flex group items-center justify-between mt-2 py-1.5 px-2 gap-2 hover:bg-ternary cursor-pointer rounded-sm hover:transform"
                          onClick={() =>
                            onHandleSavedSearchResults(node, index)
                          }
                        >
                          <div className="flex items-center gap-2">
                            <div>
                              <LineBulletinIcon />
                            </div>
                            <div className="max-w-[195px] truncate">
                              {node?.filter_name}
                            </div>
                          </div>
                          <div
                            id={`remove_search_${index}`}
                            className="scale-75 invisible group-hover:visible "
                            onClick={() => onHandleRemove(node)}
                          >
                            <CrossIcon />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </FadeIn>
            )}
          </motion.div>
          {/* )} */}
          {/* )} */}
          {/* Search ends here */}

          <div className=" h-[calc(100vh-125px)]  overflow-hidden w-[1000px]  min-w-max rounded-md bg-[#ffffff]">
            <FadeIn>
              <div className="pt-3">
                <TableTopBar
                  showDropdownValues={tableTitles}
                  setHideDropdownValues={handleTableTitle}
                  title={"Call Recordings"}
                  CSV={true}
                  handleCSVDownload={handleCSVDownload}
                  csvDownload={csvDownload}
                  // copy={true}
                  search={true}
                  searchtext={searchtext}
                  handleSearch={handleSearch}
                  setSearchText={setSearchText}
                  handleKeyPress={handleKeyPress}
                  setHandleKeyPress={setHandleKeyPress}
                  filterTable={filterTable}
                />
              </div>
              <div
                className="xl:hidden cursor-pointer pl-4 pt-2"
                onClick={() => setFilter(!filter)}
              >
                <FilterIcon />
              </div>
              <Table
                tableTitles={tableTitles}
                setTableTitles={setTableTitles}
                tableData={tableData}
                setTableData={setTableData}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                onHandleSort={onHandleSort}
                audioPlayer={true}
                resultNotFound={resultNotFound}
                actions={true}
                setPlayerModal={setPlayerModal}
                setPlayerTableData={setPlayerTableData}
                width={
                  "xl:w-[calc(100vw-394px)] w-[calc(100vw-122px)] overflow-auto"
                }
                checkBox={false}
                scrollHeight={"h-[calc(100vh-280px)] overflow-y-auto"}
                actionHandler={actionHandler}
              />

              {!resultNotFound && tableData.length > 0 && (
                <PaginationButtons
                  pageNumber={pageNumber}
                  handleChange={handleChange}
                  pageCount={pageCount}
                  perPage={perPage}
                  handlePerPage={handlePerPage}
                />
              )}
            </FadeIn>
          </div>
        </>
      ) : (
        <div className="w-full h-full flex justify-center items-center  content-center">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default LegacyCdr;
