import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  items: {};
}

const initialState: MenuState = {
  items: {},
};

const tenantDataSlice = createSlice({
  name: "tenantInfo",
  initialState,
  reducers: {
    setTenantInfo: (state, action: PayloadAction<any>) => {
      state.items = action.payload;
      console.log(state, "state");
    },
  },
});
export const { setTenantInfo } = tenantDataSlice.actions;
export default tenantDataSlice.reducer;
