import { remove, put, post, get } from "./api.service";
import { APIResponse } from "./interfaces";
import store from "../toolkit/store";
import { previousThursday } from "date-fns/fp";

export const getToken = () => {
  const state: any = store.getState();
  if (state.Simulation.simulate?.uuid) {
    return state.Simulation.simulate.uuid;
  } else {
    return state.Permissions.accountId;
  }
};
export const getTelcoId = () => {
  const state: any = store.getState();
  return state.Permissions.accountId;
};
export const isSimulateOrNot = () => {
  const state: any = store.getState();
  return state.Simulation.simulate.uuid ? true : false;
};
/**
 * Retrieves a client from the API using the provided email address.
 *
 * @param {string} email - The email address of the client.
 * @return {Promise<APIResponse>} A promise that resolves to the API response containing the client data.
 */
export async function getClientFromEmail(email: string): Promise<APIResponse> {
  const url = `email/clients?email=${email}`;
  return get(url, { skipTenant: true });
}
export async function tryKeyCloakLogin(options: any): Promise<APIResponse> {
  const url = `realms/${options.realm}/protocol/openid-connect/token`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const payload = new URLSearchParams({
    username: options.username,
    password: options.password,
    grant_type: options.grant_type,
    client_id: options.sname,
  });
  return post(url, payload, { resourceURL: "keyCloak" }, headers);
}
export async function tryKeyCloakRefresh(options: any): Promise<APIResponse> {
  const url = `realms/${options.realm}/protocol/openid-connect/token`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const payload = new URLSearchParams({
    refresh_token: options.refresh_token,
    grant_type: options.grant_type,
    client_id: options.sname,
  });
  return post(url, payload, { resourceURL: "keyCloak" }, headers);
}
export async function tryKeyCloakResetPassword(options: {
  id: string;
  realm: string;
  type: "password";
  temporary: false;
  password: string;
}): Promise<APIResponse> {
  const url = `realms/${options.realm}/users/${options.id}/reset-password`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const payload = new URLSearchParams({
    type: options.type,
    temporary: options.temporary + "",
    value: options.password,
  });
  return post(url, payload, { resourceURL: "keyCloak" }, headers);
}
export async function tryKeyCloakLogOut(options: any): Promise<APIResponse> {
  const url = `realms/${options.realm}/protocol/openid-connect/logout`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const payload = new URLSearchParams({
    refresh_token: options.refresh_token,
    client_id: options.sname,
  });
  return post(url, payload, { resourceURL: "keyCloak" }, headers);
}
export async function getChildrens(payload: any, skip = false) {
  const url = `getChildrens`;
  const result = post(url, payload, { skipHeader: skip });
  return result;
}
export async function getTelco(uuid: string) {
  const url = `getTelco/${uuid}`;
  const result = post(url, {});
  return result;
}
export async function verifyClient(payload: any) {
  const url = `verifyClient?clientId=${payload}`;
  const result = get(url, {});
  return result;
}
export async function deleteAccount(uuid: string) {
  const url = `deleteTelco/${uuid}`;
  const result = remove(url);
  return result;
}
export async function updateAccount(payload: any) {
  const url = `updateTelco/${payload.uuid}`;
  delete payload.uuid;
  const result = post(url, payload);
  return result;
}
export async function putAccount(payload: any) {
  const url = `putTelco`;
  delete payload.uuid;
  const result = put(url, payload);
  return result;
}
export async function addAccount(payload: any) {
  const url = `addTelco`;
  const result = put(url, payload);
  return result;
}
export async function getTenant(uuid: string) {
  const url = `getTenant/${uuid}`;
  const result = post(url, {});
  return result;
}
export async function deleteTenant(uuid: string) {
  const url = `deleteTenant/${uuid}`;
  const result = remove(url);
  return result;
}
export async function updateTenant(
  payload: any,
  options: any = { skipHeader: false }
) {
  const url = `updateTenant/${payload.uuid}`;
  delete payload.uuid;
  const result = post(url, payload, options);
  return result;
}
export async function putTenant(payload: any) {
  const url = `putTenant`;
  delete payload.uuid;
  const result = put(url, payload);
  return result;
}
export async function getUserPermission() {
  const url = `permissions`;
  const result = get(url, { skipTenant: true });
  return result;
}

export async function getTenantsList(payload: any) {
  const url = `telco/${getToken()}/sil_tenants`;
  const result = get(url, payload);
  return result;
}

export async function getTenantPermission(
  tenantId: string = "68cd7f1d-e6ba-4718-a289-264ba7767dca"
) {
  // https://vaspian-dev.vaspian.com/v2/permissions?tenant=
  const url = `permissions?tenant=${tenantId}&role=tenant_admin`;
  const result = get(url);
  return result;
}
export async function getAppServerTenants(payload: any) {
  const url = `telco/${getTelcoId()}/tenants`;
  const result = get(url, payload);
  return result;
}
export async function addTenantsToAppServer(payload: any) {
  const url = `tenants`;
  const result = post(url, JSON.parse(JSON.stringify(payload)));
  return result;
}
export async function provisionUser(payload: any, uuid: string) {
  const url = `tenant/${uuid}/tenant_users`;
  const result = post(url, payload);
  return result;
}
export async function getProvisionData(payload: {
  limit: number;
  pageId: number;
  sort_column: string;
  sort_direction: string;
  searchtext: string;
}) {
  let url: any = "";
  if ((payload.sort_column && payload.sort_direction) === "") {
    url = `telco/${getToken()}/tenant_users/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  } else {
    url = `telco/${getToken()}/tenant_users/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}&sort_column=${payload.sort_column
      }&sort_direction=${payload.sort_direction}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  }
  const result = get(url, {});
  return result;
}

export async function getTenantUsers(
  payload: {
    limit: number;
    pageId: number;
    sort_column: string;
    sort_direction: string;
    searchtext: string;
  },
  tenant_uuid: any
) {
  let url: any = "";
  if ((payload.sort_column && payload.sort_direction) === "") {
    url = `tenant/${tenant_uuid}/tenant_users/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  } else {
    url = `tenant/${tenant_uuid}/tenant_users/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}&sort_column=${payload.sort_column
      }&sort_direction=${payload.sort_direction}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  }
  const result = get(url, {});
  return result;
}

export async function getProvisionUserData(payload: any, tenant_uuid: string) {
  const url = `tenant/tenant_uuid/tenant_users`;
  const result = get(url, payload);
  return result;
}

export async function getDids(
  payload: {
    limit: number;
    pageId: number;
    sort_column: string;
    sort_direction: string;
    searchtext: string;
  },
  tenant_uuid: any
) {
  let url: any = "";
  if ((payload.sort_column && payload.sort_direction) === "") {
    url = `dcid/tenants/${tenant_uuid}/dids/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  } else {
    url = `dcid/tenants/${tenant_uuid}/dids/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}&sort_column=${payload.sort_column
      }&sort_direction=${payload.sort_direction}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  }
  const result = get(url, {});
  return result;
}

export async function getAllDids(tenant_uuid: string) {
  const url = `dcid/tenants/${tenant_uuid}/dids`;
  const result = get(url);
  return result;
}

export async function editProvisionUser(
  payload: any,
  tenant_uuid: any,
  tenantuser_id: any
) {
  const url = `tenant/${tenant_uuid}/tenant_users/${tenantuser_id}`;
  const result = put(url, payload);
  return result;
}

export async function deleteProvisionuser(
  payload: any,
  tenant_uuid: any,
  tenantuser_id: any
) {
  const url = `tenant/${tenant_uuid}/tenant_users/${tenantuser_id}`;
  const result = remove(url, payload);
  return result;
}
export async function downloadRecordings(payload: any) {
  const url = `cr/tenants/${getToken()}/call_recordings/download`;
  const result = post(url, payload);
  return result;
}
export async function callTranscript(payload: any) {
  const url = `cr/tenants/${getToken()}/call_recordings/transcript`;
  const result = post(url, payload);
  return result;
}
export async function getCallRecords(payload: any) {
  const url = `cr/tenants/${getToken()}/call_recordings`;
  return post(url, payload);
}
export async function getLegacyCallRecordsForDownload(payload: any) {
  // POST https://vaspian-dev.vaspian.com/v2/legacy/tenants/a900ff27-0d1a-4abf-aa94-82f2d69d3f05/legacy_calls/export
  const url = `legacy/tenants/${getToken()}/legacy_calls/export`;
  return post(url, payload);
}

export async function getCDRReportRecordsForDownload(payload: any) {
  // POST https://vaspian-dev.vaspian.com/v2/legacy/tenants/a900ff27-0d1a-4abf-aa94-82f2d69d3f05/legacy_calls/export
  const url = `cr/tenants/${getToken()}/call_reports/export`;
  return post(url, payload);
}

export async function getLegacyCDRRecordsForDownload(payload: any) {
  const url = `cr/tenants/${getToken()}/call_recordings/export`;
  return post(url, payload);
}

export async function AddSearchNameForFilter(payload: any) {
  const url = `cr/tenants/${getToken()}/rec_filters`;
  return post(url, payload);
}

export async function getSearchNameForFilter(payload: any) {
  const url = `cr/tenants/${getToken()}/rec_filters`;
  const result = get(url, payload);
  return result;
}

export async function getSearchFilterResults(payload: any, uuid: any) {
  const url = `legacy/tenants/${getToken()}/legacy_call_filters/${uuid}`;
  const result = get(url, payload);
  return result;
}

export async function removeSearchFilterResults(payload: any, uuid: any) {
  const url = `cr/tenants/${getToken()}/rec_filters/${uuid}`;
  const result = remove(url, payload);
  return result;
}

export async function getLegacyCallReport(payload: any) {
  const url = `legacy/tenants/${getToken()}/legacy_calls`;
  const result = post(url, payload);
  return result;
}

export async function getCallInfo(payload: any, uuid: any, timestamp: any) {
  const url = `legacy/tenants/${getToken()}/legacy_calls/${uuid}/segments?origination_timestamp=${timestamp}`;
  const result = get(url, payload);
  return result;
}

export async function getLegacyReportsCallInfo(
  payload: any,
  uuid: any,
  timestamp: any
) {
  const url = `cr/tenants/${getToken()}/legacy_calls/${uuid}/segments?origination_timestamp=${timestamp}`;
  const result = get(url, payload);
  return result;
}
export async function getCdrReportsInfo(
  payload: any,
  uuid: any,
  timestamp: any
) {
  const url = `cdr/tenants/${getToken()}/cdrs/${uuid}/segments?origination_ts=${timestamp}`;
  const result = get(url, payload);
  return result;
}

// export async function getCDRCalls(payload: any) {
//     const url = `cdr/tenants/${getToken()}/cdrs`
//     const result = post(url, payload)
//     return result;
// }
export async function getCDRCalls(payload: any) {
  const url = `cr/tenants/${getToken()}/call_reports`;
  const result = post(url, payload);
  return result;
}
export async function getAllTenants(
  payload: {
    limit: number;
    pageId: number;
    sort_column: string;
    sort_direction: string;
    searchtext: string;
  },
  skip = false
) {
  let url: any = "";
  if ((payload.sort_column && payload.sort_direction) === "") {
    url = `telco/${getToken()}/tenants/fetch?limit=${payload.limit}&pageId=${payload.pageId
      }${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""}`;
  } else {
    url = `telco/${getToken()}/tenants/fetch?limit=${payload.limit}&pageId=${payload.pageId
      }&sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction
      }${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""}`;
  }

  const result = get(url, {});
  return result;
}

export async function migrateTenant(payload: any) {
  const url = `telco/${getToken()}/tenants`;
  const result = post(url, payload);
  return result;
}

export async function getTenantUserDetails(payload: any) {
  const url = `user_details`;
  const result = get(url, payload);
  return result;
}

export async function getUserRoles(payload: any, id: any) {
  const url = `tenant/${id}/roles`;
  const result = get(url, payload);
  return result;
}
export async function resetPassword(payload: any, userId: any) {
  const url = `users/${userId}/reset_password`;
  const result = post(url, payload);
  return result;
}

export async function getLegacyCallReportSavedSearches(payload: any) {
  const url = `legacy/tenants/${getToken()}/legacy_calls_filters`;
  const result = get(url, payload);
  return result;
}

export async function AddSearchNameForLegacyCallReports(payload: any) {
  const url = `legacy/tenants/${getToken()}/legacy_calls_filters`;
  const result = post(url, payload);
  return result;
}

export async function getParticularFilterResultsLegacyCallReports(
  payload: any,
  id: string
) {
  const url = `legacy/tenants/${getToken()}/legacy_calls_filters/${id}`;
  const result = get(url, payload);
  return result;
}

export async function removeFilterSearchLegacyCallReports(
  payload: any,
  id: string
) {
  const url = `legacy/tenants/${getToken()}/legacy_calls_filters/${id}`;
  const result = remove(url, payload);
  return result;
}

export async function AddSearchNameForCallReports(payload: any) {
  const url = `cr/tenants/${getToken()}/call_filters`;
  const result = post(url, payload);
  return result;
}

export async function getSavedSearchesLegacyCallReports(payload: any) {
  const url = `cr/tenants/${getToken()}/call_filters`;
  const result = get(url, payload);
  return result;
}

export async function getParticularFilterResultsCallReports(
  payload: any,
  id: string
) {
  const url = `cdr/tenants/${getToken()}/report_filters/${id}`;
  const result = get(url, payload);
  return result;
}

export async function removeFilterSearchCallReports(payload: any, id: string) {
  const url = `cr/tenants/${getToken()}/call_filters/${id}`;
  const result = remove(url, payload);
  return result;
}

export async function getTenantDetails(tenant_uuid: any) {
  const url = `telco/${getToken()}/tenants/${tenant_uuid}`;
  const result = get(url, {});
  return result;
}
export async function updateTenantStatus(payload: any, tenant_uuid: any) {
  const url = `tenants/${tenant_uuid}`;
  const result = put(url, payload);
  return result;
}
export async function updateExtensionStatus(
  payload: any,
  tenant_uuid: any,
  extensionId: any
) {
  const url = `tenants/${tenant_uuid}/extensions/${extensionId}`;
  const result = put(url, payload);
  return result;
}

export async function updateDIDStatus(
  payload: any,
  tenant_uuid: any,
  did: any
) {
  let url: any = "";
  if (did !== "") {
    url = `dcid/tenants/${tenant_uuid}/dids/${did}`;
  } else {
    url = `dcid/tenants/${tenant_uuid}/dids`;
  }
  const result = put(url, payload);
  return result;
}
export async function getStorageLocation(tenant_uuid: any) {
  const url = `tenants/${tenant_uuid}/storage_location`;
  const result = get(url, {});
  return result;
}

export async function updateStorageLocation(payload: any, tenant_uuid: any) {
  const url = `tenants/${tenant_uuid}/storage_location`;
  const result = put(url, payload);
  return result;
}

export async function getExtensionsData(
  payload: {
    limit?: number;
    pageId?: number;
    sort_column?: string;
    sort_direction?: string;
    searchtext?: string;
  },
  tenant_uuid: any
) {
  let url: any = "";
  if ((payload.sort_column && payload.sort_direction) === "") {
    url = `tenants/${tenant_uuid}/extensions/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  } else {
    url = `tenants/${tenant_uuid}/extensions/fetch?limit=${payload.limit
      }&pageId=${payload.pageId}&sort_column=${payload.sort_column
      }&sort_direction=${payload.sort_direction}${payload.searchtext != "" ? `&search=${payload.searchtext}` : ""
      }`;
  }
  const result = get(url, {});
  return result;
}
export async function getNonMigratedTenants() {
  const url = `telco/${getToken()}/sil_tenants/fetch?add_tenant=true`;
  const result = get(url, {});
  return result;
}
export async function getTimeZoneRestriction(tenant_uuid: any) {
  const url = `tenants/${tenant_uuid}/timezone_restriction`;
  const result = get(url, {});
  return result;
}
export async function updateTimeZoneRestriction(
  payload: any,
  tenant_uuid: any
) {
  const url = `tenants/${tenant_uuid}/timezone_restriction`;
  const result = put(url, payload);
  return result;
}

export async function getDCIDPlans(payload: {
  limit: number;
  pageId: number;
  sort_column: string;
  sort_direction: string;
  searchtext: string;
}) {
  const url = `dcid/plans?sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction
    }&limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext !== "" ? `&search=${payload.searchtext}` : ""
    }`;
  const result = get(url, {});
  return result;
}
export async function getAllDCIDPlans() {
  const url = `dcid/plans`;
  const result = get(url, {});
  return result;
}
export async function view_Individual_Plan(
  payload: {
    limit: number;
    pageId: number;
    sort_column: string;
    sort_direction: string;
    searchtext: string;
  },
  uuid: string
) {
  const url = `dcid/plans/${uuid}?sort_column=${payload.sort_column
    }&sort_direction=${payload.sort_direction}&limit=${payload.limit}&pageId=${payload.pageId
    }${payload.searchtext !== "" ? `&search=${payload.searchtext}` : ""}`;
  const result = get(url, {});
  return result;
}

export async function get_NewPlan_Data(payload: {
  limit: number;
  pageId: number;
  sort_column: string;
  sort_direction: string;
  searchtext: string;
}) {
  const url = `dcid/npas?sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction
    }&limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext !== "" ? `&search=${payload.searchtext}` : ""
    }`;
  const result = get(url, {});
  return result;
}

export async function add_New_DCID_Plan(payload: any) {
  const url = `dcid/plans`;
  return post(url, payload);
}

export async function delete_DCID_Plan(plan_id: string) {
  const url = `dcid/plans/${plan_id}`;
  const result = remove(url);
  return result;
}

export async function get_States() {
  const url = `dcid/states`;
  const result = get(url, {});
  return result;
}

export async function get_Rate_Centers(
  payload: {
    limit: number;
    pageId: number;
    sort_column: string;
    sort_direction: string;
    searchtext: string;
  },
  state_code: string
) {
  const url = `dcid/states/${state_code}/npas?sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction}&limit=${payload.limit}&pageId=${payload.pageId}`;
  const result = get(url, {});
  return result;
}

export async function get_Dcid(
  payload: {
    limit: number;
    pageId: number;
    sort_column: string;
    sort_direction: string;
    searchtext: string;
  },
  tenant_id: string
) {
  const url = `dcid/tenants/${tenant_id}/lists/fetch?sort_column=${payload.sort_column
    }&sort_direction=${payload.sort_direction}&limit=${payload.limit}&pageId=${payload.pageId
    }${payload.searchtext !== "" ? `&search=${payload.searchtext}` : ""}`;
  const result = get(url, {});
  return result;
}

export async function get_Particular_Dcid_Data(
  tenant_id: string,
  list_id: string
) {
  const url = `dcid/tenants/${tenant_id}/lists/${list_id}`;
  const result = get(url, {});
  return result;
}

export async function get_All_Particular_Dcid_Data(
  payload: {
    limit: number;
    pageId: number;
    sort_column: string;
    sort_direction: string;
    searchtext: string;
  },
  tenant_id: string,
  list_id: string
) {
  const url = `dcid/tenants/${tenant_id}/lists/${list_id}?limit=${payload.limit
    }&pageId=${payload.pageId}&sort_column=${payload.sort_column
    }&sort_direction=${payload.sort_direction}${payload.searchtext !== "" ? `&search=${payload.searchtext}` : ""
    }`;
  const result = get(url, {});
  return result;
}

export async function add_DCID(payload: any, tenant_id: any) {
  const url = `dcid/tenants/${tenant_id}/lists`;
  const result = post(url, payload);
  return result;
}

export async function edit_DCID(payload: any, tenant_id: any, list_id: any) {
  const url = `dcid/tenants/${tenant_id}/lists/${list_id}`;
  const result = put(url, payload);
  return result;
}

export async function delete_DCID(tenant_id: any, list_id: any) {
  const url = `dcid/tenants/${tenant_id}/lists/${list_id}`;
  const result = remove(url);
  return result;
}

export async function delete_DCID_DID(tenant_id: any, did_id: any) {
  const url = `dcid/tenants/${tenant_id}/lists/${did_id}`;
  const result = remove(url);
  return result;
}

export async function getAllStates() {
  const url = `dcid/npas/states`;
  const result = get(url, {});
  return result;
}
export async function getAllRateCenters(state_code: string) {
  const url = `dcid/npas/states/${state_code}/rate_centers`;
  const result = get(url, {});
  return result;
}
export async function getAllNpas(state_code: string, rate_center: string) {
  const url = `dcid/npas/states/${state_code}/rate_centers/${rate_center}/npas`;
  const result = get(url, {});
  return result;
}

export async function getAllNpaNumbers(
  state_code: string,
  rate_center_abbr: string,
  npa: string,
  hide_unassigned: boolean
) {
  const url = `dcid/npas/states/${state_code}/rate_centers/${rate_center_abbr}/npas/${npa}/search?hide_unassigned=${hide_unassigned}`;
  const result = get(url, {});
  return result;
}

export async function add_DCID_Number(
  payload: any,
  tenant_id: any,
  list_id: any
) {
  const url = `dcid/tenants/${tenant_id}/lists/${list_id}/add_number`;
  const result = post(url, payload);
  return result;
}

export async function getDidsList(payload: {
  limit: number;
  pageId: number;
  sort_column: string;
  sort_direction: string;
  searchtext: string;
}) {
  const url = `dcid/dids?sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction
    }&limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext !== "" ? `&search=${payload.searchtext}` : ""
    }`;
  const result = get(url, {});
  return result;
}

export async function purchaseDIDS(payload: any) {
  const url = `dcid/dids`;
  const result = post(url, payload);
  return result;
}

export async function get_DID_Life_Cycle(did_id: string) {
  const url = `dcid/dids/${did_id}/life_cycle`;
  const result = get(url, {});
  return result;
}

export async function add_Rate_Center(payload: any) {
  const url = `dcid/npas`;
  const result = post(url, payload);
  return result;
}

export async function release_DID(did_id: any) {
  const url = `dcid/dids/${did_id}`;
  const result = remove(url);
  return result;
}

export async function get_All_DCID(tenant_id: any) {
  const url = `dcid/tenants/${tenant_id}/lists`;
  const result = get(url);
  return result;
}

export async function getTopCallers(payload: any, tenant_id: any) {
  const url = `cr/tenants/${tenant_id}/reports/top_callers`;
  const result = post(url, payload);
  return result;
}

export async function getTopAnswers(payload: any, tenant_id: any) {
  const url = `cr/tenants/${tenant_id}/reports/top_answers`;
  const result = post(url, payload);
  return result;
}

export async function getTopExtensions(payload: any, tenant_id: any) {
  const url = `cr/tenants/${tenant_id}/reports/top_extensions`;
  const result = post(url, payload);
  return result;
}

export async function getTopNumbers(payload: any, tenant_id: any) {
  const url = `cr/tenants/${tenant_id}/reports/top_numbers`;
  const result = post(url, payload);
  return result;
}

export async function getMissedCalls(payload: any, tenant_id: any) {
  const url = `cr/tenants/${tenant_id}/reports/missed_calls`;
  const result = post(url, payload);
  return result;
}

export async function getBusiestHour(payload: any, tenant_id: any) {
  const url = `cr/tenants/${tenant_id}/reports/busiest_hour`;
  const result = post(url, payload);
  return result;
}

export async function getBusiestDay(payload: any, tenant_id: any) {
  const url = `cr/tenants/${tenant_id}/reports/busiest_day`;
  const result = post(url, payload);
  return result;
}

export async function getTopCallersCSV(payload: any, tab: string) {
  const url = `cr/tenants/${getToken()}/reports/${tab}/export`;
  return post(url, payload);
}

export async function getScrubToolLists(
  start: number,
  sort_column: string,
  sort_order: string,
  match: string,
  limit: number
) {
  var param = `start=${start}&sort_column=${sort_column}&sort_order=${sort_order}`;
  if (match) {
    param += `&match=${match}`;
  } else if (limit) {
    param += `&limit=${limit}`;
  }
  const url = `srv/scrubtool${isSimulateOrNot() ? "/tenants/" + getToken() : ""
    }/lists?${param}`;
  const result = get(url);
  return result;
}
export async function getwebhooksLists(
  start: number,
  sort_column: string,
  sort_order: string,
  match: string,
  limit: number
) {
  var param = `pageId=${start}&sort_column=${sort_column}&sort_direction=${sort_order.toUpperCase()}`;
  if (match) {
    param += `&search=${match}`;
  }
  param += `&limit=${limit || 25}`;

  // vaspian-dev.vaspian.com/v2/liveboard/tenants/{tenant_id}/webhooks/fetch?sort_column=name&sort_direction=ASC&pageId=1&limit=25
  const url = `liveboard/tenants/${getToken()}/webhooks/fetch?${param}`;
  const result = get(url);
  return result;
}
export async function saveWebhook(payload: any) {
  const url = `liveboard/tenants/${getToken()}/webhooks`;
  const result = post(url, payload);
  return result;
}
export async function updateWebhook(payload: any, uuid: any) {
  const url = `liveboard/tenants/${getToken()}/webhooks/${uuid}`;
  const result = put(url, payload);
  return result;
}
export async function getWebhookEvents() {
  const url = `liveboard/webhooks/list_events`;
  const result = get(url);
  return result;
}
export async function getWebhookById(id: any) {
  const url = `liveboard/tenants/${getToken()}/webhooks/${id}`;
  const result = get(url);
  return result;
}
export async function removeWebhookById(id: any) {
  const url = `liveboard/tenants/${getToken()}/webhooks/${id}`;
  const result = remove(url);
  return result;
}

export async function uploadScrubFile(payload: File) {
  const url = `srv/scrubtool/fileupload`;
  const formData = new FormData();
  const headers = { "Content-Type": "multipart/form-data" };
  formData.append("document", payload);
  const result = post(url, formData, { skipTenant: true }, headers);
  return result;
}

// export async function getScrubToolImportedNumbersLists(id: any) {
//   const url = `dcid/tenants/${getToken()}/lists/${id}`;
//   const result = get(url);
//   return result;
// }

export async function getScrubToolImportedNumbersLists(
  id: any,
  start: number,
  sort_column: string,
  sort_order: string,
  match: string,
  limit: number
) {
  var param = `start=${start}&sort_column=${sort_column}&sort_order=${sort_order}`;
  if (match) {
    param += `&match=${match}`;
  } else if (limit) {
    param += `&limit=${limit}`;
  }
  const url = `srv/scrubtool/lists/${id}/list_numbers?${param}`;
  const result = get(url);
  return result;
}

export async function getScrubToolImportedNumbersInfo(id: any) {
  const url = `srv/scrubtool/lists/${id}/list_info`;
  const result = get(url);
  return result;
}
export async function performZipCrunch(id: any) {
  const url = `srv/scrubtool/zipcrunch_export`;
  const result = post(url, { list_id: id });
  return result;
}

export async function performZipCrunchByCarrier(id: any, carrier: string) {
  const url = `srv/scrubtool/zipcrunch_export`;
  const result = post(url, { list_id: id, carrier: carrier });
  return result;
}
export async function performZipCrunchStatus(id: any) {
  const url = `srv/scrubtool/lists/${id}/zipcrunch_export_info`;
  const result = get(url);
  return result;
}

export async function addScrubToolList(payload: any) {
  const url = `srv/scrubtool${isSimulateOrNot() ? "/tenants/" + getToken() : ""
    }/lists`;
  const result = post(url, payload);
  return result;
}

export async function getTenantSyncTimestamp(tenant_id: any) {
  const url = `tenants/${tenant_id}/sync`;
  const result = get(url);
  return result;
}

export async function syncTenant(payload: any, tenant_id: any) {
  const url = `tenants/${tenant_id}/sync`;
  const result = post(url, payload);
  return result;
}

export async function getZipCrunchTenants(payload: {
  pageNumber: number;
  tenantSearch: string;
}) {
  const url = `srv/scrubtool/zipcrunch_customers?orderBy=org&asc=true&PageSize=16&pageNumber=${payload.pageNumber
    }${payload.tenantSearch !== "" ? `&searchTerms=${payload.tenantSearch}` : ""
    }`;
  const result = get(url);
  return result;
}
export async function getZipCrunchKey(key_id: string) {
  const url = `srv/scrubtool/zipcrunch_customers/${key_id}/zipcrunch_licensekeys`;
  const result = get(url);
  return result;
}

export async function zipCrunchConnect(payload: any, tenantid: any) {
  const url = `srv/scrubtool/tenants/${tenantid}/zipcrunch_connect`;
  const result = post(url, payload);
  return result;
}

export async function getConnectorsInfo(tenant_id: any) {
  const url = `srv/scrubtool/tenants/${tenant_id}/connectors`;
  const result = get(url);
  return result;
}

export async function disconnectZipCrunch(tenant_id: any) {
  const url = `srv/scrubtool/tenants/${tenant_id}/zipcrunch_disconnect`;
  const result = remove(url);
  return result;
}

export async function getZohoAccounts(payload: { search?: string, page?: number, per_page?: number }) {
  console.log("payload", payload.search);
  const url = `srv/scrubtool/zoho_customers?page=${payload?.page}&per_page=${payload?.per_page}${payload?.search ? `&search=${payload?.search}` : ""}`;
  const result = get(url);
  return result;
}
export async function getZohoConnect(tenant_id: any, account: any) {
  const url = `srv/scrubtool/tenants/${tenant_id}/zoho_connect`;
  const result = post(url, account);
  return result;
}
export async function getZohoDisconnect(tenant_id: any, account: any) {
  const url = `srv/scrubtool/tenants/${tenant_id}/zoho_disconnect`;
  const result = remove(url, account);
  return result;
}

export async function enableActionState(
  smdrId: any,
  action: any,
  payload: any
) {
  const url = `liveboard/tenants/${getToken()}/calls/${smdrId}/monitor`;
  const result = post(url, payload);
  return result;
}

export async function getSmsUsersList(payload: {
  limit: number;
  pageId: number;
  sort_column: string;
  sort_direction: string;
  searchtext: string;
}) {
  const url = `tenants/${getToken()}/sms_users/fetch?sort_column=${payload.sort_column
    }&sort_direction=${payload.sort_direction}&limit=${payload.limit}&pageId=${payload.pageId
    }${payload.searchtext !== "" ? `&search=${payload.searchtext}` : ""}`;
  const result = get(url);
  return result;
}

export async function addSmsUser(payload: any) {
  const url = `tenants/${getToken()}/sms_users`;
  const result = post(url, payload);
  return result;
}

export async function getSmsDids() {
  const url = `dcid/tenants/${getToken()}/dids/sms_dids`;
  const result = get(url);
  return result;
}

export async function getMigrationEvents(sil_tenant_id: any) {
  const url = `tenants/migration_events?sil_tenant_id=${sil_tenant_id}`;
  const result = get(url);
  return result;
}

export async function editSmsDids(user_id: string, payload: any) {
  const url = `tenants/${getToken()}/sms_users/${user_id}`;
  const result = put(url, payload);
  return result;
}

export async function deleteSmsUser(user_id: string, payload: any) {
  const url = `tenants/${getToken()}/sms_users/${user_id}`;
  const result = remove(url, payload);
  return result;
}

export async function getAllDisplayGroups(search?: string) {
  const url = `liveboard/tenants/${getToken()}/display_groups${search ? `/fetch?search=${search}` : ""
    }`;
  const result = get(url);
  return result;
}

export async function getAllDisplayGroupEntities() {
  const url = `liveboard/tenants/${getToken()}/display_groups/list_entities`;
  const result = get(url);
  return result;
}

export async function getDisplayGroupById(id: any) {
  const url = `liveboard/tenants/${getToken()}/display_groups/${id}`;
  const result = get(url);
  return result;
}

export async function updateDisplayGroup(id: any, payload: any) {
  const url = `liveboard/tenants/${getToken()}/display_groups/${id}`;
  const result = put(url, payload);
  return result;
}
export async function addDisplayGroup(payload: any) {
  const url = `liveboard/tenants/${getToken()}/display_groups`;
  const result = post(url, payload);
  return result;
}
export async function deleteDisplayGroup(group_id: string) {
  const url = `liveboard/tenants/${getToken()}/display_groups/${group_id}`;
  const result = remove(url, {});
  return result;
}
export async function addCorrectify(payload: { file: any; data: any }) {
  const url = `cr/tenants/${getToken()}/call_recordings/transcript/correctify`;
  const formData = new FormData();
  const headers = { "Content-Type": "multipart/form-data" };
  formData.append("file", payload.file);
  formData.append("data", JSON.stringify(payload.data));
  const result = post(url, formData, { skipTenant: true }, headers);
  return result;
}
export async function regenerateTranscript(payload: any) {
  const url = `cr/tenants/${getToken()}/call_recordings/transcript/regenerate`;
  const result = post(url, payload);
  return result;
}

export async function getDidGateways() {
  const url = `dcid/gateways`;
  const result = get(url);
  return result;
}

export async function updateDidGateways(tenant_uuid: any, did_uuid: string, payload: any) {
  const url = `
dcid/tenants/${tenant_uuid}/dids/${did_uuid}`;
  const result = put(url, payload);
  return result;
}

// export async function addNonMigratedTenants(payload: any) {
//     const url = `tenants`
//     const result = post(url, payload)
//     return result;
// }
