import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getScrubToolImportedNumbersInfo,
  getScrubToolImportedNumbersLists,
  performZipCrunchByCarrier,
  performZipCrunchStatus,
} from "../../services/api";
import Table from "../../atom/table";
import { TableSortIconDownArrow } from "../../utils/icons/defaultIcons";
import { title } from "process";
import BarGraph from "../../atom/Charts/BarGraph";
import DoughnutChart from "../../atom/Charts/doughnutChart";
import PaginationButtons from "../../atom/pagination";
import TableTopBar from "../modal/tableTopBar";
import axios from "axios";
import { getENVData } from "../../config/environment";
import Notification from "../../atom/Notification";
import { Backdrop } from "@mui/material";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import Lottiefy from "../../atom/lottie/lottie";

let flag = false;
function ViewList() {
  const intervalRef = useRef<any>(null);
  const listData = useSelector((state: any) => state.listData.data);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [searchtext, setSearchText] = useState<any>("");
  const [zipCrunchDD, setZipCrunchDD] = useState<any>([]);
  const [exportLoader, setExportLoader] = useState<any>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const leftInfo = [
    { key: "total_numbers", title: "Total Imported Numbers" },
    { key: "wireless_numbers", title: "Wireless Phone Numbers" },
    { key: "landline_numbers", title: "Landline Phone Numbers" },
    { key: "invalid_numbers", title: "Invalid Phone Numbers" },
  ];
  const [graphData, setGraphData] = useState({
    labels: [],
    color: ["#FEBA12"],
    data: [],
    backgroundColor: "#98A6DD",
  });
  const [doughnutData, setDoughnutData] = useState({
    labels: ["Imported ", "Wireless ", "Landline ", "Invalid "],
    color: ["#A3D027", "#99B7FF", "#6693FF", "#EE5823"],
    data: [],
    backgroundColor: "#98A6DD",
  });
  const [tableData, setTableData] = React.useState<any>([]);
  const [listInfo, setListInfo] = React.useState<any>({});
  const [resultNotFound, setResultNotFound] = React.useState(false);
  const [csvDownload, setCsvDownload] = React.useState(false);
  const [CSVDropdownList, setCSVDropdownList] = React.useState<any>([
    {
      title: "Valid Numbers",
      key: "valid",
      loading: false,
    },
    {
      title: "Invalid Numbers",
      key: "invalid",
      loading: false,
    },
    {
      title: "Wireless Numbers",
      key: "wireless",
      loading: false,
    },
  ]);
  const [tableTitles, setTableTitles] = React.useState([
    {
      key: "number",
      title: "Imported Number",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "cleaned_number",
      title: "Clean Number",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: true,
      },
    },
    {
      key: "carrier",
      title: "Carrier",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "time_zone",
      title: "Time Zone",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: false,
        default: false,
      },
    },
    {
      key: "time",
      title: "Last Updated",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "state",
      title: "State",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
    {
      key: "valid",
      title: "Valid",
      type: "boolean",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },

    {
      key: "wireless",
      title: "Wireless",
      type: "boolean",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
      hide: {
        status: true,
        default: false,
      },
    },
  ]);
  const [loader, setLoader] = React.useState(false);
  const [sort, setSort] = useState<any>({
    key: "imported_on",
    order: "desc",
  });
  const onHandleFetch = (options: {
    limit?: number;
    match?: string;
    start?: number;
    sort_column?: string;
    sort_order?: string;
  }) => {
    getScrubToolImportedNumbersLists(
      listData.uuid,
      options.start
        ? (options.start - 1) * perPage + 1
        : (pageNumber - 1) * perPage + 1,
      options.sort_column || sort.key,
      options.sort_order
        ? options.sort_order.toLowerCase()
        : sort.order.toLowerCase(),
      options.match || searchtext,
      options.limit || perPage
    ).then((result: any) => {
      setLoader(true);
      if (result?.data?.numbers?.length > 0) {
        setLoader(false);
        setResultNotFound(false);
        setTableData(result?.data);
        setPageCount(result?.data?.size);
      } else {
        setResultNotFound(true);
        setLoader(false);
      }
    });
    getScrubToolImportedNumbersInfo(listData.uuid)
      .then((result: any) => {
        console.log("result", result);
        setListInfo(result?.data);
        const newData: any = [];
        leftInfo.forEach((node) => {
          newData.push(result?.data[node.key]);
        });
        setDoughnutData((prevData) => ({
          ...prevData,
          data: newData,
        }));
        const carrierData: any = [];
        const carrierLabel: any = [];
        result?.data?.carriers.forEach((node: any) => {
          carrierData.push(node.number);
          carrierLabel.push(node.name);
        });

        setGraphData((prevData) => ({
          ...prevData,
          data: carrierData,
          labels: carrierLabel,
        }));
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };
  const handleSearchTemp = (value: any) => {
    console.log("valuevaluevalue", value);
    setSearchText(value);
  };
  const handleChange = (pNUm: number) => {
    get_All_Lists(pNUm);
    setPageNumber(pNUm);
  };
  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, start: 1 });
  };
  const handleTableTitle = (data: any) => {
    setTableTitles(data);
    var status: any = {};
    data
      .filter((node: any) => node?.hide?.status !== undefined)
      .map((node: any) => {
        status[node.key] = node.hide.status;
      });
    window.sessionStorage.setItem(
      "lagacyCDRTableTitle",
      JSON.stringify(status)
    );
    // cookies.set("lagacyCDRTableTitle", JSON.stringify(status), {
    //   path: "/",
    // });
  };
  const handleCSVDownload = async (node: any) => {
    const data = [...CSVDropdownList];
    const filter = data.find((n: any) => n.key === node.key);
    filter.loading = true;
    setCSVDropdownList(data);
    try {
      const path: string =
        getENVData().baseURL.replace("/v2/", "") +
        listInfo.output_files[node.key];

      const response = await axios.get(path, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.download = `${node.title}.csv`;

      // Trigger the download
      link.click();

      // Clean up the URL object after the download
      URL.revokeObjectURL(url);
      const data = [...CSVDropdownList];
      const filter = data.find((n: any) => n.key === node.key);
      filter.loading = false;
      setCSVDropdownList(data);
    } catch (error) {
      const data = [...CSVDropdownList];
      const filter = data.find((n: any) => n.key === node.key);
      filter.loading = false;
      setCSVDropdownList(data);
    }
  };
  const handleSearch = (match: string) => {
    setPageNumber(1);
    setSearchText(match);
    onHandleFetch({ match: match, start: 1, limit: perPage });
  };
  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_order: orderBy });
  };
  const handleKeyPress = (e: any) => {
    //it triggers by pressing the enter key
    if (e.key.toLowerCase() === "enter" && searchtext !== null) {
      handleSearch(searchtext);
    }
  };
  const handleZipCrunch = (data: any, action: any) => {
    console.log("action", action);
    if (action === "ZipCrunchSubmit") {
      setExportLoader(true);

      const selected = zipCrunchDD
        .filter((n: any) => n.checked === true)
        .map((n: any) => n.carrier);
      if (selected.length > 0) {
        performZipCrunchByCarrier(listData.uuid, selected).then(
          (result: any) => {
            if (result.success) {
              // Notification("Success", "Zipcrunch started", "success");
              Notification("", "Sending to Zip Crunch", "success");

              zipCrunchStatus();
              setExportLoader(false);
            } else {
              Notification("Error", result.message, "danger");
              setExportLoader(false);
            }
          }
        );
      } else {
        Notification("Error", "Please select carrier", "danger");
        setExportLoader(false);
      }
    } else if (action === "all") {
      const data1 = [...zipCrunchDD];
      const check =
        data1.filter((n: any) => n.checked === false).length > 0 ? true : false;
      data1.forEach((node: any) => {
        node.checked = check;
      });
      setZipCrunchDD(data1);
    } else {
      const data1 = [...zipCrunchDD];
      const filter = data1.find((n: any) => n.uuid === data.uuid);
      filter.checked = !filter.checked;
      setZipCrunchDD(data1);
      setExportLoader(false);
    }
  };
  const get_All_Lists = (pgNum: number) => {
    setPageNumber(pgNum);
    onHandleFetch({ start: pgNum });
  };
  const zipCrunchStatus = () => {
    clearInterval(intervalRef.current);
    performZipCrunchStatus(listData.uuid).then((result: any) => {
      const data = result.data;

      data.forEach((node: any) => {
        node.checked = false;

        if (node.status === "progress") {
          flag = true;
        }
        let hasprogress = data.some((node: any) => node.status === "progress");

        if (!hasprogress) {
          flag = false;
        }
      });
      setZipCrunchDD(data);
      if (flag) {
        intervalRef.current = setInterval(() => {
          zipCrunchStatus();
        }, 5000);
      }
    });
  };
  useEffect(() => {
    get_All_Lists(1);
    zipCrunchStatus();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  //Production
  return (
    <div data-testid="provison" className="h-full py-3 flex relative gap-2.5">
      <Backdrop className="!z-[99999999999]" open={loader}>
        <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
      </Backdrop>
      <div className="h-[calc(100vh-125px)] w-[calc(100vw-120px)] overflow-hidden rounded-md overflow-y-auto bg-[#ffffff]">
        <div className="pt-3">
          <TableTopBar
            title={listData?.name}
            button={false}
            showDropdownValues={tableTitles}
            setHideDropdownValues={handleTableTitle}
            CSV={true}
            handleSearch={handleSearch}
            handleKeyPress={true}
            setSearchText={handleSearchTemp}
            onhandleKeyPress={handleKeyPress}
            CSVDropdown={CSVDropdownList}
            handleCSVDownload={handleCSVDownload}
            permission={"tools.scrub_tool.get"}
            zipCrunch={true}
            zipCrunchDD={zipCrunchDD}
            handleZipCrunch={handleZipCrunch}
            exportLoader={exportLoader}
            zipflag={flag}
          />
        </div>
        <div className="gap-2.5 p-2 flex justify-stretch w-full h-[330px]">
          <div className="w-[300px]">
            <table className="w-full">
              {leftInfo.map((node: any, index: number) => (
                <tr className={`${index % 2 === 0 ? "bg-[#F2F4FB]" : ""}`}>
                  <td className="p-2">{node.title}</td>
                  <td className="p-2">{listInfo[node.key]}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className="w-[calc(50%-150px)] border border-[#eee] rounded-md p-4">
            <BarGraph
              labels={graphData.labels}
              color={graphData.color}
              data={graphData.data}
              hhmmss={""}
              multipleBar={false}
              maxBar={4}
            />
          </div>
          <div className="w-[calc(50%-150px)] border border-[#eee] rounded-md p-10 flex justify-center">
            <DoughnutChart
              labels={doughnutData.labels}
              set={5}
              data={doughnutData.data}
              border={10}
              cutout={"50%"}
              color={doughnutData.color}
            />
          </div>
        </div>
        <Table
          tableData={tableData?.numbers}
          tableTitles={tableTitles}
          resultNotFound={resultNotFound}
          width={"w-[calc(100vw-122px)] overflow-auto"}
          scrollHeight={""}
          onHandleSort={onHandleSort}
          setTableTitles={setTableTitles}
        />
        {/* {!resultNotFound && tableData?.numbers.length > 0 && ( */}
        <PaginationButtons
          pageNumber={pageNumber}
          handleChange={handleChange}
          pageCount={pageCount}
          perPage={perPage}
          handlePerPage={handlePerPage}
        />
        {/* )} */}
      </div>
    </div>
  );
}
export default ViewList;
