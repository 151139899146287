import ThemedButton from "../../atom/ThemedButton/themedButton";
import { useState } from "react";
import { addCorrectify } from "../../services/api";
import Notification from "../../atom/Notification";

const TrainYourModel = (props: any) => {
  const { item, selectedText, onCloseModal, audioBlob } = props;
  const [trainigWord, setTrainigWord] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = () => {
    if (trainigWord.replace(/\s/g, "") === "") {
      Notification("Training Word", "Please enter Training Word", "danger");
      return;
    }
    setLoading(true);
    const audioFile = new File([audioBlob], "audio-training.wav", {
      type: "audio/wav",
    });

    addCorrectify({
      file: audioFile,
      data: {
        find: selectedText,
        replace: trainigWord,
      },
    })
      .then((result) => {
        if (!result.success) {
          setLoading(false);
          Notification("Failed", "Oops! Something went wrong", "danger");
        } else {
          Notification(
            "Success",
            "Training Word added successfully",
            "success"
          );
          onCloseModal();
          setLoading(false);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="px-2 w-[404px]">
        <div className="flex  gap-2 pb-4">
          <div className="w-1/2">Selected Word/s</div>
          <div className="w-1/2">{selectedText}</div>
        </div>
        <div className="flex gap-2 ">
          <div className="w-1/2 py-2">Training Word/s</div>
          <div className="w-1/2 ">
            <input
              autoFocus
              value={trainigWord}
              onChange={(e) =>
                setTrainigWord(e.target.value.replace(/\s+/g, " "))
              }
              className="border w-[200px] border-[gray] rounded-md p-2"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="text-right w-full  ml-2 mt-8">
        <ThemedButton
          children={"Cancel"}
          icon="custom"
          theme="secondary"
          click={onCloseModal}
        />
        <ThemedButton
          children={"Submit"}
          icon="custom"
          theme="primary"
          click={handleSubmit}
          loading={loading}
        />
      </div>
    </>
  );
};
export default TrainYourModel;
